"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWRewardModeToJSON = exports.OWRewardModeFromJSONTyped = exports.OWRewardModeFromJSON = exports.OWRewardMode = void 0;
/**
 * The method at which we process time logs for time quests
 * @export
 * @enum {string}
 */
var OWRewardMode;
(function (OWRewardMode) {
    OWRewardMode["ALLTIME"] = "ALLTIME";
})(OWRewardMode = exports.OWRewardMode || (exports.OWRewardMode = {}));
function OWRewardModeFromJSON(json) {
    return OWRewardModeFromJSONTyped(json, false);
}
exports.OWRewardModeFromJSON = OWRewardModeFromJSON;
function OWRewardModeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OWRewardModeFromJSONTyped = OWRewardModeFromJSONTyped;
function OWRewardModeToJSON(value) {
    return value;
}
exports.OWRewardModeToJSON = OWRewardModeToJSON;
