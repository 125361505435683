"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetNftMintTransactionsSortToJSON = exports.GetNftMintTransactionsSortFromJSONTyped = exports.GetNftMintTransactionsSortFromJSON = exports.GetNftMintTransactionsSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetNftMintTransactionsSort;
(function (GetNftMintTransactionsSort) {
    GetNftMintTransactionsSort["CreatedAt"] = "createdAt";
})(GetNftMintTransactionsSort = exports.GetNftMintTransactionsSort || (exports.GetNftMintTransactionsSort = {}));
function GetNftMintTransactionsSortFromJSON(json) {
    return GetNftMintTransactionsSortFromJSONTyped(json, false);
}
exports.GetNftMintTransactionsSortFromJSON = GetNftMintTransactionsSortFromJSON;
function GetNftMintTransactionsSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetNftMintTransactionsSortFromJSONTyped = GetNftMintTransactionsSortFromJSONTyped;
function GetNftMintTransactionsSortToJSON(value) {
    return value;
}
exports.GetNftMintTransactionsSortToJSON = GetNftMintTransactionsSortToJSON;
