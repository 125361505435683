"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWTimeLogToJSON = exports.OWTimeLogFromJSONTyped = exports.OWTimeLogFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OWTimeLogFromJSON(json) {
    return OWTimeLogFromJSONTyped(json, false);
}
exports.OWTimeLogFromJSON = OWTimeLogFromJSON;
function OWTimeLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': json['userId'],
        'gameId': json['gameId'],
        'gameTitle': !(0, runtime_1.exists)(json, 'gameTitle') ? undefined : json['gameTitle'],
        'event': json['event'],
        'timePlayed': json['timePlayed'],
        'isUsed': json['isUsed'],
        'rewardTransaction': !(0, runtime_1.exists)(json, 'rewardTransaction') ? undefined : json['rewardTransaction'],
        'quests': !(0, runtime_1.exists)(json, 'quests') ? undefined : (json['quests'].map(_1.OWRewardFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
    };
}
exports.OWTimeLogFromJSONTyped = OWTimeLogFromJSONTyped;
function OWTimeLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'gameId': value.gameId,
        'gameTitle': value.gameTitle,
        'event': value.event,
        'timePlayed': value.timePlayed,
        'isUsed': value.isUsed,
        'rewardTransaction': value.rewardTransaction,
        'quests': value.quests === undefined ? undefined : (value.quests.map(_1.OWRewardToJSON)),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
    };
}
exports.OWTimeLogToJSON = OWTimeLogToJSON;
