"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeUserToJSON = exports.ChallengeUserFromJSONTyped = exports.ChallengeUserFromJSON = void 0;
var runtime_1 = require("../runtime");
function ChallengeUserFromJSON(json) {
    return ChallengeUserFromJSONTyped(json, false);
}
exports.ChallengeUserFromJSON = ChallengeUserFromJSON;
function ChallengeUserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'nickname': json['nickname'],
        'gravatar': !(0, runtime_1.exists)(json, 'gravatar') ? undefined : json['gravatar'],
    };
}
exports.ChallengeUserFromJSONTyped = ChallengeUserFromJSONTyped;
function ChallengeUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'nickname': value.nickname,
        'gravatar': value.gravatar,
    };
}
exports.ChallengeUserToJSON = ChallengeUserToJSON;
