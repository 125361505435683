"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeRewardToJSON = exports.ChallengeRewardFromJSONTyped = exports.ChallengeRewardFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ChallengeRewardFromJSON(json) {
    return ChallengeRewardFromJSONTyped(json, false);
}
exports.ChallengeRewardFromJSON = ChallengeRewardFromJSON;
function ChallengeRewardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'amount': !(0, runtime_1.exists)(json, 'amount') ? undefined : json['amount'],
        'rewardType': (0, _1.OWRewardTypeFromJSON)(json['rewardType']),
        'nftTrackerId': !(0, runtime_1.exists)(json, 'nftTrackerId') ? undefined : json['nftTrackerId'],
        'name': json['name'],
        'description': json['description'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : json['image'],
        'disclaimer': !(0, runtime_1.exists)(json, 'disclaimer') ? undefined : json['disclaimer'],
    };
}
exports.ChallengeRewardFromJSONTyped = ChallengeRewardFromJSONTyped;
function ChallengeRewardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'amount': value.amount,
        'rewardType': (0, _1.OWRewardTypeToJSON)(value.rewardType),
        'nftTrackerId': value.nftTrackerId,
        'name': value.name,
        'description': value.description,
        'image': value.image,
        'disclaimer': value.disclaimer,
    };
}
exports.ChallengeRewardToJSON = ChallengeRewardToJSON;
