"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWRewardToJSON = exports.OWRewardFromJSONTyped = exports.OWRewardFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OWRewardFromJSON(json) {
    return OWRewardFromJSONTyped(json, false);
}
exports.OWRewardFromJSON = OWRewardFromJSON;
function OWRewardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'game': !(0, runtime_1.exists)(json, 'game') ? undefined : json['game'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'isActive': json['isActive'],
        'showQuestCompleteNotification': !(0, runtime_1.exists)(json, 'showQuestCompleteNotification') ? undefined : json['showQuestCompleteNotification'],
        'questCompleteNotificationMessage': !(0, runtime_1.exists)(json, 'questCompleteNotificationMessage') ? undefined : json['questCompleteNotificationMessage'],
        'rewardMax': !(0, runtime_1.exists)(json, 'rewardMax') ? undefined : json['rewardMax'],
        'rewardMaxType': !(0, runtime_1.exists)(json, 'rewardMaxType') ? undefined : (0, _1.OWRewardMaxTypeFromJSON)(json['rewardMaxType']),
        'questReset': (0, _1.QuestResetFromJSON)(json['questReset']),
        'rewardCategory': (0, _1.OWLogCategoryFromJSON)(json['rewardCategory']),
        'eventType': json['eventType'],
        'target': json['target'],
        'milestones': !(0, runtime_1.exists)(json, 'milestones') ? undefined : (json['milestones'].map(_1.OWRewardMilestoneFromJSON)),
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'questRewards': !(0, runtime_1.exists)(json, 'questRewards') ? undefined : (json['questRewards'].map(_1.OWQuestRewardFromJSON)),
        'challenge': !(0, runtime_1.exists)(json, 'challenge') ? undefined : json['challenge'],
        'userStat': !(0, runtime_1.exists)(json, 'userStat') ? undefined : (0, _1.OWUserStatFromJSON)(json['userStat']),
        'onAllSupportedGames': !(0, runtime_1.exists)(json, 'onAllSupportedGames') ? undefined : json['onAllSupportedGames'],
        'mode': !(0, runtime_1.exists)(json, 'mode') ? undefined : (0, _1.OWRewardModeFromJSON)(json['mode']),
        'id': json['id'],
    };
}
exports.OWRewardFromJSONTyped = OWRewardFromJSONTyped;
function OWRewardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'game': value.game,
        'name': value.name,
        'description': value.description,
        'isActive': value.isActive,
        'showQuestCompleteNotification': value.showQuestCompleteNotification,
        'questCompleteNotificationMessage': value.questCompleteNotificationMessage,
        'rewardMax': value.rewardMax,
        'rewardMaxType': (0, _1.OWRewardMaxTypeToJSON)(value.rewardMaxType),
        'questReset': (0, _1.QuestResetToJSON)(value.questReset),
        'rewardCategory': (0, _1.OWLogCategoryToJSON)(value.rewardCategory),
        'eventType': value.eventType,
        'target': value.target,
        'milestones': value.milestones === undefined ? undefined : (value.milestones.map(_1.OWRewardMilestoneToJSON)),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'questRewards': value.questRewards === undefined ? undefined : (value.questRewards.map(_1.OWQuestRewardToJSON)),
        'challenge': value.challenge,
        'userStat': (0, _1.OWUserStatToJSON)(value.userStat),
        'onAllSupportedGames': value.onAllSupportedGames,
        'mode': (0, _1.OWRewardModeToJSON)(value.mode),
        'id': value.id,
    };
}
exports.OWRewardToJSON = OWRewardToJSON;
