"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeOnboardingDetailsToJSON = exports.ChallengeOnboardingDetailsFromJSONTyped = exports.ChallengeOnboardingDetailsFromJSON = void 0;
function ChallengeOnboardingDetailsFromJSON(json) {
    return ChallengeOnboardingDetailsFromJSONTyped(json, false);
}
exports.ChallengeOnboardingDetailsFromJSON = ChallengeOnboardingDetailsFromJSON;
function ChallengeOnboardingDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': json['title'],
        'description': json['description'],
    };
}
exports.ChallengeOnboardingDetailsFromJSONTyped = ChallengeOnboardingDetailsFromJSONTyped;
function ChallengeOnboardingDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'description': value.description,
    };
}
exports.ChallengeOnboardingDetailsToJSON = ChallengeOnboardingDetailsToJSON;
