"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeOnboardingToJSON = exports.ChallengeOnboardingFromJSONTyped = exports.ChallengeOnboardingFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ChallengeOnboardingFromJSON(json) {
    return ChallengeOnboardingFromJSONTyped(json, false);
}
exports.ChallengeOnboardingFromJSON = ChallengeOnboardingFromJSON;
function ChallengeOnboardingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': json['title'],
        'description': json['description'],
        'getStartedSteps': !(0, runtime_1.exists)(json, 'getStartedSteps') ? undefined : (json['getStartedSteps'].map(_1.ChallengeOnboardingDetailsFromJSON)),
        'instantReward': !(0, runtime_1.exists)(json, 'instantReward') ? undefined : (0, _1.ChallengeOnboardingDetailsFromJSON)(json['instantReward']),
        'prizePool': !(0, runtime_1.exists)(json, 'prizePool') ? undefined : (0, _1.ChallengeOnboardingDetailsFromJSON)(json['prizePool']),
    };
}
exports.ChallengeOnboardingFromJSONTyped = ChallengeOnboardingFromJSONTyped;
function ChallengeOnboardingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'description': value.description,
        'getStartedSteps': value.getStartedSteps === undefined ? undefined : (value.getStartedSteps.map(_1.ChallengeOnboardingDetailsToJSON)),
        'instantReward': (0, _1.ChallengeOnboardingDetailsToJSON)(value.instantReward),
        'prizePool': (0, _1.ChallengeOnboardingDetailsToJSON)(value.prizePool),
    };
}
exports.ChallengeOnboardingToJSON = ChallengeOnboardingToJSON;
