"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrizePoolTypeToJSON = exports.PrizePoolTypeFromJSONTyped = exports.PrizePoolTypeFromJSON = exports.PrizePoolType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var PrizePoolType;
(function (PrizePoolType) {
    PrizePoolType["GIFTCARD"] = "GIFT_CARD";
    PrizePoolType["XP"] = "XP";
    PrizePoolType["FUEL"] = "FUEL";
    PrizePoolType["NFT"] = "NFT";
    PrizePoolType["MERCH"] = "MERCH";
    PrizePoolType["OTHER"] = "OTHER";
})(PrizePoolType = exports.PrizePoolType || (exports.PrizePoolType = {}));
function PrizePoolTypeFromJSON(json) {
    return PrizePoolTypeFromJSONTyped(json, false);
}
exports.PrizePoolTypeFromJSON = PrizePoolTypeFromJSON;
function PrizePoolTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.PrizePoolTypeFromJSONTyped = PrizePoolTypeFromJSONTyped;
function PrizePoolTypeToJSON(value) {
    return value;
}
exports.PrizePoolTypeToJSON = PrizePoolTypeToJSON;
