"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserSummaryResponseToJSON = exports.GetUserSummaryResponseFromJSONTyped = exports.GetUserSummaryResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
function GetUserSummaryResponseFromJSON(json) {
    return GetUserSummaryResponseFromJSONTyped(json, false);
}
exports.GetUserSummaryResponseFromJSON = GetUserSummaryResponseFromJSON;
function GetUserSummaryResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalXp': !(0, runtime_1.exists)(json, 'totalXp') ? undefined : json['totalXp'],
        'totalTime': !(0, runtime_1.exists)(json, 'totalTime') ? undefined : json['totalTime'],
        'totalGames': !(0, runtime_1.exists)(json, 'totalGames') ? undefined : json['totalGames'],
        'isDevourPlayUser': !(0, runtime_1.exists)(json, 'isDevourPlayUser') ? undefined : json['isDevourPlayUser'],
    };
}
exports.GetUserSummaryResponseFromJSONTyped = GetUserSummaryResponseFromJSONTyped;
function GetUserSummaryResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totalXp': value.totalXp,
        'totalTime': value.totalTime,
        'totalGames': value.totalGames,
        'isDevourPlayUser': value.isDevourPlayUser,
    };
}
exports.GetUserSummaryResponseToJSON = GetUserSummaryResponseToJSON;
