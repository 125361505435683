"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOverwolfEventMappingsResponseToJSON = exports.GetOverwolfEventMappingsResponseFromJSONTyped = exports.GetOverwolfEventMappingsResponseFromJSON = void 0;
var _1 = require("./");
function GetOverwolfEventMappingsResponseFromJSON(json) {
    return GetOverwolfEventMappingsResponseFromJSONTyped(json, false);
}
exports.GetOverwolfEventMappingsResponseFromJSON = GetOverwolfEventMappingsResponseFromJSON;
function GetOverwolfEventMappingsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'eventMappings': (json['eventMappings'].map(_1.OWGameEventMapFromJSON)),
    };
}
exports.GetOverwolfEventMappingsResponseFromJSONTyped = GetOverwolfEventMappingsResponseFromJSONTyped;
function GetOverwolfEventMappingsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'eventMappings': (value.eventMappings.map(_1.OWGameEventMapToJSON)),
    };
}
exports.GetOverwolfEventMappingsResponseToJSON = GetOverwolfEventMappingsResponseToJSON;
