"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetExistingUserResponseToJSON = exports.GetExistingUserResponseFromJSONTyped = exports.GetExistingUserResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
function GetExistingUserResponseFromJSON(json) {
    return GetExistingUserResponseFromJSONTyped(json, false);
}
exports.GetExistingUserResponseFromJSON = GetExistingUserResponseFromJSON;
function GetExistingUserResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_exists': !(0, runtime_1.exists)(json, 'exists') ? undefined : json['exists'],
        'disabled': !(0, runtime_1.exists)(json, 'disabled') ? undefined : json['disabled'],
    };
}
exports.GetExistingUserResponseFromJSONTyped = GetExistingUserResponseFromJSONTyped;
function GetExistingUserResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'exists': value._exists,
        'disabled': value.disabled,
    };
}
exports.GetExistingUserResponseToJSON = GetExistingUserResponseToJSON;
