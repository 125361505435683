"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeInfoResponseAllOfToJSON = exports.ChallengeInfoResponseAllOfFromJSONTyped = exports.ChallengeInfoResponseAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ChallengeInfoResponseAllOfFromJSON(json) {
    return ChallengeInfoResponseAllOfFromJSONTyped(json, false);
}
exports.ChallengeInfoResponseAllOfFromJSON = ChallengeInfoResponseAllOfFromJSON;
function ChallengeInfoResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isAccepted': !(0, runtime_1.exists)(json, 'isAccepted') ? undefined : json['isAccepted'],
        'prizes': (json['prizes'].map(_1.PrizePoolFromJSON)),
        'winners': !(0, runtime_1.exists)(json, 'winners') ? undefined : (json['winners'].map(_1.ChallengeMembershipFromJSON)),
        'brandMapSlug': !(0, runtime_1.exists)(json, 'brandMapSlug') ? undefined : json['brandMapSlug'],
        'isInstantRewardClaimed': !(0, runtime_1.exists)(json, 'isInstantRewardClaimed') ? undefined : json['isInstantRewardClaimed'],
    };
}
exports.ChallengeInfoResponseAllOfFromJSONTyped = ChallengeInfoResponseAllOfFromJSONTyped;
function ChallengeInfoResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isAccepted': value.isAccepted,
        'prizes': (value.prizes.map(_1.PrizePoolToJSON)),
        'winners': value.winners === undefined ? undefined : (value.winners.map(_1.ChallengeMembershipToJSON)),
        'brandMapSlug': value.brandMapSlug,
        'isInstantRewardClaimed': value.isInstantRewardClaimed,
    };
}
exports.ChallengeInfoResponseAllOfToJSON = ChallengeInfoResponseAllOfToJSON;
