"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuildQuestModeToJSON = exports.GuildQuestModeFromJSONTyped = exports.GuildQuestModeFromJSON = exports.GuildQuestMode = void 0;
/**
 * The method at which we process time logs for time quests
 * @export
 * @enum {string}
 */
var GuildQuestMode;
(function (GuildQuestMode) {
    GuildQuestMode["ALLTIME"] = "ALLTIME";
    GuildQuestMode["DIFFDAYS"] = "DIFF_DAYS";
    GuildQuestMode["SINGLEDAY"] = "SINGLE_DAY";
})(GuildQuestMode = exports.GuildQuestMode || (exports.GuildQuestMode = {}));
function GuildQuestModeFromJSON(json) {
    return GuildQuestModeFromJSONTyped(json, false);
}
exports.GuildQuestModeFromJSON = GuildQuestModeFromJSON;
function GuildQuestModeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GuildQuestModeFromJSONTyped = GuildQuestModeFromJSONTyped;
function GuildQuestModeToJSON(value) {
    return value;
}
exports.GuildQuestModeToJSON = GuildQuestModeToJSON;
