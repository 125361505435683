"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOverwolfEventDumpsResponseAllOfToJSON = exports.GetOverwolfEventDumpsResponseAllOfFromJSONTyped = exports.GetOverwolfEventDumpsResponseAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetOverwolfEventDumpsResponseAllOfFromJSON(json) {
    return GetOverwolfEventDumpsResponseAllOfFromJSONTyped(json, false);
}
exports.GetOverwolfEventDumpsResponseAllOfFromJSON = GetOverwolfEventDumpsResponseAllOfFromJSON;
function GetOverwolfEventDumpsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'eventDumps': !(0, runtime_1.exists)(json, 'eventDumps') ? undefined : (json['eventDumps'].map(_1.OWEventDumpFromJSON)),
    };
}
exports.GetOverwolfEventDumpsResponseAllOfFromJSONTyped = GetOverwolfEventDumpsResponseAllOfFromJSONTyped;
function GetOverwolfEventDumpsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'eventDumps': value.eventDumps === undefined ? undefined : (value.eventDumps.map(_1.OWEventDumpToJSON)),
    };
}
exports.GetOverwolfEventDumpsResponseAllOfToJSON = GetOverwolfEventDumpsResponseAllOfToJSON;
