"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrizePoolToJSON = exports.PrizePoolFromJSONTyped = exports.PrizePoolFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function PrizePoolFromJSON(json) {
    return PrizePoolFromJSONTyped(json, false);
}
exports.PrizePoolFromJSON = PrizePoolFromJSON;
function PrizePoolFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'quantity': json['quantity'],
        'challenge': (0, _1.ChallengeFromJSON)(json['challenge']),
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'image': (0, _1.AssetFromJSON)(json['image']),
        'type': (0, _1.PrizePoolTypeFromJSON)(json['type']),
        'quantityRewarded': !(0, runtime_1.exists)(json, 'quantityRewarded') ? undefined : json['quantityRewarded'],
        'amount': !(0, runtime_1.exists)(json, 'amount') ? undefined : json['amount'],
        'nftTracker': !(0, runtime_1.exists)(json, 'nftTracker') ? undefined : json['nftTracker'],
        'notifiedWinner': !(0, runtime_1.exists)(json, 'notifiedWinner') ? undefined : json['notifiedWinner'],
        'winnerMessage': !(0, runtime_1.exists)(json, 'winnerMessage') ? undefined : json['winnerMessage'],
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}
exports.PrizePoolFromJSONTyped = PrizePoolFromJSONTyped;
function PrizePoolToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'quantity': value.quantity,
        'challenge': (0, _1.ChallengeToJSON)(value.challenge),
        'description': value.description,
        'image': (0, _1.AssetToJSON)(value.image),
        'type': (0, _1.PrizePoolTypeToJSON)(value.type),
        'quantityRewarded': value.quantityRewarded,
        'amount': value.amount,
        'nftTracker': value.nftTracker,
        'notifiedWinner': value.notifiedWinner,
        'winnerMessage': value.winnerMessage,
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.PrizePoolToJSON = PrizePoolToJSON;
