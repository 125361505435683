"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftPurchaseRequestBodyToJSON = exports.NftPurchaseRequestBodyFromJSONTyped = exports.NftPurchaseRequestBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function NftPurchaseRequestBodyFromJSON(json) {
    return NftPurchaseRequestBodyFromJSONTyped(json, false);
}
exports.NftPurchaseRequestBodyFromJSON = NftPurchaseRequestBodyFromJSON;
function NftPurchaseRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'fiatInDpay': !(0, runtime_1.exists)(json, 'fiatInDpay') ? undefined : json['fiatInDpay'],
        'paymentMethodId': !(0, runtime_1.exists)(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
        'isCoinbase': !(0, runtime_1.exists)(json, 'isCoinbase') ? undefined : json['isCoinbase'],
        'isLootBox': !(0, runtime_1.exists)(json, 'isLootBox') ? undefined : json['isLootBox'],
    };
}
exports.NftPurchaseRequestBodyFromJSONTyped = NftPurchaseRequestBodyFromJSONTyped;
function NftPurchaseRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'fiatInDpay': value.fiatInDpay,
        'paymentMethodId': value.paymentMethodId,
        'quantity': value.quantity,
        'isCoinbase': value.isCoinbase,
        'isLootBox': value.isLootBox,
    };
}
exports.NftPurchaseRequestBodyToJSON = NftPurchaseRequestBodyToJSON;
