"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestaurantSubTypeToJSON = exports.RestaurantSubTypeFromJSONTyped = exports.RestaurantSubTypeFromJSON = exports.RestaurantSubType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var RestaurantSubType;
(function (RestaurantSubType) {
    RestaurantSubType["RESTAURANT"] = "RESTAURANT";
    RestaurantSubType["GROCERY"] = "GROCERY";
    RestaurantSubType["CONVENIENCE"] = "CONVENIENCE";
})(RestaurantSubType = exports.RestaurantSubType || (exports.RestaurantSubType = {}));
function RestaurantSubTypeFromJSON(json) {
    return RestaurantSubTypeFromJSONTyped(json, false);
}
exports.RestaurantSubTypeFromJSON = RestaurantSubTypeFromJSON;
function RestaurantSubTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.RestaurantSubTypeFromJSONTyped = RestaurantSubTypeFromJSONTyped;
function RestaurantSubTypeToJSON(value) {
    return value;
}
exports.RestaurantSubTypeToJSON = RestaurantSubTypeToJSON;
