"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOverwolfEventDumpsSortToJSON = exports.GetOverwolfEventDumpsSortFromJSONTyped = exports.GetOverwolfEventDumpsSortFromJSON = exports.GetOverwolfEventDumpsSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetOverwolfEventDumpsSort;
(function (GetOverwolfEventDumpsSort) {
    GetOverwolfEventDumpsSort["CREATEDAT"] = "CREATED_AT";
})(GetOverwolfEventDumpsSort = exports.GetOverwolfEventDumpsSort || (exports.GetOverwolfEventDumpsSort = {}));
function GetOverwolfEventDumpsSortFromJSON(json) {
    return GetOverwolfEventDumpsSortFromJSONTyped(json, false);
}
exports.GetOverwolfEventDumpsSortFromJSON = GetOverwolfEventDumpsSortFromJSON;
function GetOverwolfEventDumpsSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetOverwolfEventDumpsSortFromJSONTyped = GetOverwolfEventDumpsSortFromJSONTyped;
function GetOverwolfEventDumpsSortToJSON(value) {
    return value;
}
exports.GetOverwolfEventDumpsSortToJSON = GetOverwolfEventDumpsSortToJSON;
