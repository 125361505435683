"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetNftMintTransactionsResponseAllOfToJSON = exports.GetNftMintTransactionsResponseAllOfFromJSONTyped = exports.GetNftMintTransactionsResponseAllOfFromJSON = void 0;
var _1 = require("./");
function GetNftMintTransactionsResponseAllOfFromJSON(json) {
    return GetNftMintTransactionsResponseAllOfFromJSONTyped(json, false);
}
exports.GetNftMintTransactionsResponseAllOfFromJSON = GetNftMintTransactionsResponseAllOfFromJSON;
function GetNftMintTransactionsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nftMintTransactions': (json['nftMintTransactions'].map(_1.NftMintTransactionFromJSON)),
    };
}
exports.GetNftMintTransactionsResponseAllOfFromJSONTyped = GetNftMintTransactionsResponseAllOfFromJSONTyped;
function GetNftMintTransactionsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nftMintTransactions': (value.nftMintTransactions.map(_1.NftMintTransactionToJSON)),
    };
}
exports.GetNftMintTransactionsResponseAllOfToJSON = GetNftMintTransactionsResponseAllOfToJSON;
