"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftRewardPrizeTypeToJSON = exports.NftRewardPrizeTypeFromJSONTyped = exports.NftRewardPrizeTypeFromJSON = exports.NftRewardPrizeType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var NftRewardPrizeType;
(function (NftRewardPrizeType) {
    NftRewardPrizeType["XP"] = "XP";
    NftRewardPrizeType["FUEL"] = "FUEL";
    NftRewardPrizeType["NFT"] = "NFT";
    NftRewardPrizeType["PROMO"] = "PROMO";
    NftRewardPrizeType["MERCH"] = "MERCH";
    NftRewardPrizeType["GIFTCARD"] = "GIFT_CARD";
    NftRewardPrizeType["OTHER"] = "OTHER";
})(NftRewardPrizeType = exports.NftRewardPrizeType || (exports.NftRewardPrizeType = {}));
function NftRewardPrizeTypeFromJSON(json) {
    return NftRewardPrizeTypeFromJSONTyped(json, false);
}
exports.NftRewardPrizeTypeFromJSON = NftRewardPrizeTypeFromJSON;
function NftRewardPrizeTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.NftRewardPrizeTypeFromJSONTyped = NftRewardPrizeTypeFromJSONTyped;
function NftRewardPrizeTypeToJSON(value) {
    return value;
}
exports.NftRewardPrizeTypeToJSON = NftRewardPrizeTypeToJSON;
