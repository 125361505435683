"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengePrizeBodyToJSON = exports.ChallengePrizeBodyFromJSONTyped = exports.ChallengePrizeBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ChallengePrizeBodyFromJSON(json) {
    return ChallengePrizeBodyFromJSONTyped(json, false);
}
exports.ChallengePrizeBodyFromJSON = ChallengePrizeBodyFromJSON;
function ChallengePrizeBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : json['image'],
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, _1.PrizePoolTypeFromJSON)(json['type']),
        'amount': !(0, runtime_1.exists)(json, 'amount') ? undefined : json['amount'],
        'nftTracker': !(0, runtime_1.exists)(json, 'nftTracker') ? undefined : json['nftTracker'],
        'winnerMessage': !(0, runtime_1.exists)(json, 'winnerMessage') ? undefined : json['winnerMessage'],
    };
}
exports.ChallengePrizeBodyFromJSONTyped = ChallengePrizeBodyFromJSONTyped;
function ChallengePrizeBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'quantity': value.quantity,
        'description': value.description,
        'image': value.image,
        'type': (0, _1.PrizePoolTypeToJSON)(value.type),
        'amount': value.amount,
        'nftTracker': value.nftTracker,
        'winnerMessage': value.winnerMessage,
    };
}
exports.ChallengePrizeBodyToJSON = ChallengePrizeBodyToJSON;
