"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftPurchaseToJSON = exports.NftPurchaseFromJSONTyped = exports.NftPurchaseFromJSON = void 0;
var runtime_1 = require("../runtime");
function NftPurchaseFromJSON(json) {
    return NftPurchaseFromJSONTyped(json, false);
}
exports.NftPurchaseFromJSON = NftPurchaseFromJSON;
function NftPurchaseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'user': json['user'],
        'price': json['price'],
        'gasFee': json['gasFee'],
        'quantity': json['quantity'],
        'total': json['total'],
        'stripeTotal': json['stripeTotal'],
        'dpay': json['dpay'],
        'fiatInDpay': json['fiatInDpay'],
        'isComplete': json['isComplete'],
        'isPending': json['isPending'],
        'dpayTransaction': !(0, runtime_1.exists)(json, 'dpayTransaction') ? undefined : json['dpayTransaction'],
        'dpayTransactionRefund': !(0, runtime_1.exists)(json, 'dpayTransactionRefund') ? undefined : json['dpayTransactionRefund'],
        'paymentMethodId': !(0, runtime_1.exists)(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'paymentIntentId': !(0, runtime_1.exists)(json, 'paymentIntentId') ? undefined : json['paymentIntentId'],
        'paymentIntentRefundId': !(0, runtime_1.exists)(json, 'paymentIntentRefundId') ? undefined : json['paymentIntentRefundId'],
        'walletAddress': json['walletAddress'],
        'coinbaseChargeId': !(0, runtime_1.exists)(json, 'coinbaseChargeId') ? undefined : json['coinbaseChargeId'],
        'isCoinbase': !(0, runtime_1.exists)(json, 'isCoinbase') ? undefined : json['isCoinbase'],
        'nftMintTransaction': !(0, runtime_1.exists)(json, 'nftMintTransaction') ? undefined : json['nftMintTransaction'],
        'transactionHash': !(0, runtime_1.exists)(json, 'transactionHash') ? undefined : json['transactionHash'],
    };
}
exports.NftPurchaseFromJSONTyped = NftPurchaseFromJSONTyped;
function NftPurchaseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'user': value.user,
        'price': value.price,
        'gasFee': value.gasFee,
        'quantity': value.quantity,
        'total': value.total,
        'stripeTotal': value.stripeTotal,
        'dpay': value.dpay,
        'fiatInDpay': value.fiatInDpay,
        'isComplete': value.isComplete,
        'isPending': value.isPending,
        'dpayTransaction': value.dpayTransaction,
        'dpayTransactionRefund': value.dpayTransactionRefund,
        'paymentMethodId': value.paymentMethodId,
        'paymentIntentId': value.paymentIntentId,
        'paymentIntentRefundId': value.paymentIntentRefundId,
        'walletAddress': value.walletAddress,
        'coinbaseChargeId': value.coinbaseChargeId,
        'isCoinbase': value.isCoinbase,
        'nftMintTransaction': value.nftMintTransaction,
        'transactionHash': value.transactionHash,
    };
}
exports.NftPurchaseToJSON = NftPurchaseToJSON;
