"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftMintTransactionToJSON = exports.NftMintTransactionFromJSONTyped = exports.NftMintTransactionFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function NftMintTransactionFromJSON(json) {
    return NftMintTransactionFromJSONTyped(json, false);
}
exports.NftMintTransactionFromJSON = NftMintTransactionFromJSON;
function NftMintTransactionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'user': json['user'],
        'nftTracker': !(0, runtime_1.exists)(json, 'nftTracker') ? undefined : json['nftTracker'],
        'isComplete': json['isComplete'],
        'contractAddress': !(0, runtime_1.exists)(json, 'contractAddress') ? undefined : json['contractAddress'],
        'error': !(0, runtime_1.exists)(json, 'error') ? undefined : json['error'],
        'walletAddress': !(0, runtime_1.exists)(json, 'walletAddress') ? undefined : json['walletAddress'],
        'transactionHash': !(0, runtime_1.exists)(json, 'transactionHash') ? undefined : json['transactionHash'],
        'userLevel': !(0, runtime_1.exists)(json, 'userLevel') ? undefined : json['userLevel'],
        'nftId': !(0, runtime_1.exists)(json, 'nftId') ? undefined : json['nftId'],
        'receipt': !(0, runtime_1.exists)(json, 'receipt') ? undefined : json['receipt'],
        'type': (0, _1.NftTypeFromJSON)(json['type']),
        'chain': (0, _1.EVMChainFromJSON)(json['chain']),
    };
}
exports.NftMintTransactionFromJSONTyped = NftMintTransactionFromJSONTyped;
function NftMintTransactionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'user': value.user,
        'nftTracker': value.nftTracker,
        'isComplete': value.isComplete,
        'contractAddress': value.contractAddress,
        'error': value.error,
        'walletAddress': value.walletAddress,
        'transactionHash': value.transactionHash,
        'userLevel': value.userLevel,
        'nftId': value.nftId,
        'receipt': value.receipt,
        'type': (0, _1.NftTypeToJSON)(value.type),
        'chain': (0, _1.EVMChainToJSON)(value.chain),
    };
}
exports.NftMintTransactionToJSON = NftMintTransactionToJSON;
