"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetChallengesSortToJSON = exports.GetChallengesSortFromJSONTyped = exports.GetChallengesSortFromJSON = exports.GetChallengesSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetChallengesSort;
(function (GetChallengesSort) {
    GetChallengesSort["CREATEDAT"] = "CREATED_AT";
    GetChallengesSort["NAME"] = "NAME";
})(GetChallengesSort = exports.GetChallengesSort || (exports.GetChallengesSort = {}));
function GetChallengesSortFromJSON(json) {
    return GetChallengesSortFromJSONTyped(json, false);
}
exports.GetChallengesSortFromJSON = GetChallengesSortFromJSON;
function GetChallengesSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetChallengesSortFromJSONTyped = GetChallengesSortFromJSONTyped;
function GetChallengesSortToJSON(value) {
    return value;
}
exports.GetChallengesSortToJSON = GetChallengesSortToJSON;
