import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux"
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import "./style/index.scss";
import GA4React from "ga-4-react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const ga4react = new GA4React(
		process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE,
		{
				debug_mode: process.env.NODE_ENV === "development",
		},
		[ /* additional code, optional */],
		5000 /* timeout, optional, defaults is 5000 */,
		{} /* { nonce: ['first-script-is-async','second-script'] } */
);

const queryClient = new QueryClient()

const container = document.getElementById("root");

createRoot(container!).render(
		<Provider store={store}>
				<PersistGate
						persistor={persistor}
						loading={null}
				>
						<QueryClientProvider client={queryClient}>
								<App/>
						</QueryClientProvider>
				</PersistGate>
		</Provider>
);

void ga4react.initialize()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

