"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThirdwebRegisterBodyToJSON = exports.ThirdwebRegisterBodyFromJSONTyped = exports.ThirdwebRegisterBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function ThirdwebRegisterBodyFromJSON(json) {
    return ThirdwebRegisterBodyFromJSONTyped(json, false);
}
exports.ThirdwebRegisterBodyFromJSON = ThirdwebRegisterBodyFromJSON;
function ThirdwebRegisterBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'anonymousSpinValue': !(0, runtime_1.exists)(json, 'anonymousSpinValue') ? undefined : json['anonymousSpinValue'],
        'referralCode': !(0, runtime_1.exists)(json, 'referralCode') ? undefined : json['referralCode'],
        'signedPayload': json['signedPayload'],
    };
}
exports.ThirdwebRegisterBodyFromJSONTyped = ThirdwebRegisterBodyFromJSONTyped;
function ThirdwebRegisterBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'anonymousSpinValue': value.anonymousSpinValue,
        'referralCode': value.referralCode,
        'signedPayload': value.signedPayload,
    };
}
exports.ThirdwebRegisterBodyToJSON = ThirdwebRegisterBodyToJSON;
